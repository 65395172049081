import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["listIdentifier", "sortField"];
  static values = {
    listIdentifiers: Object,
    sortFieldPlaceholderText: String,
    sortableAttributesTranslations: Object
  };

  connect() {
    $(this.listIdentifierTarget).on("change", this.updateFields.bind(this));
    this.prepopulateFields();
  }

  disconnect() {
    $(this.listIdentifierTarget).off("change", this.updateFields.bind(this));
  }

  updateFields() {
    const listIdentifier = this.listIdentifierTarget.value;
    this.populateSortFieldOptions(listIdentifier);
  }

  prepopulateFields() {
    const listIdentifier = this.listIdentifierTarget.value;
    if (listIdentifier) {
      this.populateSortFieldOptions(listIdentifier, true);
    }
  }

  populateSortFieldOptions(listIdentifier, preserveCurrentValue = false) {
    const sortableAttributes = this.listIdentifiersValue[listIdentifier]?.sortable_attributes || [];
    const translations = this.sortableAttributesTranslationsValue[listIdentifier] || {};
    const currentValue = preserveCurrentValue ? this.sortFieldTarget.dataset.currentValue : "";

    this.updateDropdown(this.sortFieldTarget, sortableAttributes, currentValue, translations);
  }

  updateDropdown(dropdown, options, selectedValue, translations) {
    dropdown.innerHTML = "";

    const placeholder = document.createElement("option");
    placeholder.value = "";
    placeholder.textContent = this.sortFieldPlaceholderTextValue;
    dropdown.appendChild(placeholder);

    options.forEach((option) => {
      const opt = document.createElement("option");
      opt.value = option;
      opt.textContent = translations[option] || option;
      if (option === selectedValue) {
        opt.selected = true;
      }
      dropdown.appendChild(opt);
    });
  }
}
